.mobile-nav {
	justify-content: space-between;

	font-family: "Space Grotesk";
}


.sidebar-btn {
	background-color: transparent;
	color: white;
	border: none;
	font-size: 36px;
}

.mobile-nav--location {
	font-size: 25px;
}

.mobile-app {
	position: relative;
}

.modal-bg {
	width: 100vw;
	height: 100vh;

	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 101;
}

.hide {
	display: none;
}

.judges{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    padding: 35px;
}

.judges--table{
    background-color: #030B27;
    width: 100%;
    max-width: 600px;
    padding: 15px;
    border-radius: 20px;

    display: flex;
    flex-direction: column;

    gap: 15px;
}

.name-col{
    max-width: 350px;
}

.user-col{
    max-width: 300px;
    width: 100%;
}

.judges-table--row{
    border: none;
    border-radius: 15px;
	background-color: #eae9eb;

    display: flex;
    align-items: center;
}

.judges-table--inputs{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 5px;
    margin-left: 5px;
    width: 100%;
}

.judges-table--input{
    border: none;
    padding-left: 10px;
    width: 100%;
    font-size: 1rem;

    background-color: #eae9eb;

}

.judges-table--btn{
    margin-left: auto;
    margin-right: 10px;
}

.judges--delete-btn{
    background-color: red;
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    color: white;

}

.judges--add-btn-container{
    margin-left: 10px;
    display: flex;
    width: 100%;
    justify-content: start;
}



.judges--add-btn{
    background-color: rgb(7, 207, 7);
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    color: white;
    font-size: 20px;
}




.register{
    display: flex;
	align-items: center;
	flex-direction: column;

	font-family: "Space Grotesk";
}

.form{
    margin-top: 35px;

	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 15px;
	background-color: #030b27;
	max-width: 600px;
	width: 80%;
	padding: 25px;

	color: white;
	border: none;
	border-radius: 15px;
}

.form > input {
    width: 100%;
	height: 30px;
}

.form-btn{
    border: none;
	border-radius: 5px;
	padding: 5px;
	padding-inline: 20px;

	background-color: #eae9eb;

	cursor: pointer;
}

.form--link{
    color: white;
}

.error-msg {
	background-color: pink;
	padding: 5px;
	width: 100%;
	text-align: center;

	border-radius: 5px;

	color: red;
	font-size: 0.85rem;
}

.success-msg{
	background-color: greenyellow;
	padding: 5px;
	width: 100%;
	text-align: center;

	border-radius: 5px;

	color: green;
	font-size: 0.85rem;
}

.event {
  margin-bottom: 25px;
}

.event--options {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 15px;

  background-color: #EAE9EB;

}

.event--options > ul {
  list-style: none;
  display: flex;
  gap: 5px;
  margin: 0;
  padding: 0;

}

.event--options > ul > li {
  font-family: "Space Grotesk";
  padding: 5px;
  padding-inline: 10px;

  border: 3px solid white;
  border-radius: 15px;

  cursor: pointer;
}

.event--options--selected{
  background-color: #030b27;
	color: white;
}

.performances {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  /* align-items: center; */
  gap: 25px;

  padding: 25px;
}

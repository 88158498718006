.sidebar{
    position: fixed;
    top: 0;
    right: 0;
    color: white;
    z-index: 102;
    height: 100vh;
    width: 250px;
    background-color: #030B27;

    display: flex;
    flex-direction: column;
    gap: 15px;

    padding: 15px;

    font-family: "Space Grotesk";

}

.sidebar > div {
    width: 100%;
}

.close-btn--container{
    text-align: end;
}

.sidebar--close-btn{
    background-color: transparent;
    border: none;
    color: white;
    font-size: 36px;

    cursor: pointer;
}


.sidebar-nav--items{
    list-style: none;
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: column;
    flex: 1 1 1 ;
    gap: 15px;
}

.sidebar-nav--item{
    border: 2px solid white;
    border-radius: 15px;
    padding-inline: 10px;
    padding-block: 3px;

    cursor: pointer;
}

.mobile-nav--logout{
    display: flex;
    gap: 15px;
    align-items: center;

    cursor: pointer;
}


.nav-link{
    text-decoration: inherit;
    color: white;
}
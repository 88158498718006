.login {
	display: flex;
	align-items: center;
	flex-direction: column;

	font-family: "Space Grotesk";
}

.login--form {
	margin-top: 35px;

	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 15px;
	background-color: #030b27;
	max-width: 600px;
	width: 80%;
	padding: 25px;

	color: white;
	border: none;
	border-radius: 15px;
}
.login--form > input {
	width: 100%;
	height: 30px;
}

.login--btn {
	border: none;
	border-radius: 5px;
	padding: 5px;
	padding-inline: 20px;

	background-color: #eae9eb;
}



.form--link-container {
	text-align: end;
	width: 100%;
	margin-top: 15px;
	display: flex;
	flex-direction: column;

	gap: 5px;
}

.form--link{
	color: white;
}

.results {
	display: flex;
	flex-direction: column;
	padding: 15px;
	justify-content: center;
	flex: 1 1;

	font-family: "Space Grotesk";
}

.results--options {
	background-color: #eae9eb;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex: 1 1 1;
	flex-wrap: wrap;

	gap: 10px;

	padding: 10px;

	font-family: "Space Grotesk";
}

.results--options > ul {
	margin: 0;
	padding: 0;

	list-style: none;

	gap: 7px;

	display: flex;
	flex-direction: row;
	align-items: center;
}

.results--options > ul > li {
	margin: 0;
	padding: 0;
	cursor: pointer;

	border: 3px solid white;
	border-radius: 20px;
	padding: 10px;

	font-size: 12px;
}

.results--options--selected {
	background-color: #030b27;
	color: white;
}

.results--scores {
	column-count: 1;

	background-color: #030b27;
	width: 100%;
	border: none;
	border-radius: 10px;

	padding: 15px;
}

.results--info {
	display: flex;
	align-items: center;
	padding: 15px;
	background-color: #eae9eb;

	gap: 10px;
	margin-bottom: 5px;
	border: none;
	border-radius: 2px;
}

.results--position {
	font-weight: bold;
}

.results--country {
	font-size: 24px;
}

.results--score {
	margin-left: auto;
	text-align: center;

	font-size: 20px;
	font-weight: bold;
}

@media all and (min-width: 600px) {
	.results {
		padding: 35px;
	}

	.results--scores {
		column-count: 2;
		position: relative;
	}

	.results--info {
		position: relative;
		-webkit-column-break-inside: avoid;
		page-break-inside: avoid;
		break-inside: avoid;
	}
}

.edit--judge-column {
	/* width: 1200px; */
}

.edit--table-container {
	width: 100vw;
	overflow: auto;

	display: grid;
}

.edit--table-header {
	border: 1px solid red;
	display: flex;
}

.sticky-column {
	position: "sticky" !important;
	left: 0;
	background: "white";
}

.edit--country-column {
	/* border: 1px solid red; */
	text-align: center;
	/* display: flex; */
	/* justify-content: center; */
	/* align-items: center; */
}

.edit--input {
	width: 60px;
	height: 45px;

	text-align: center;
	font-size: 25px;

    margin: 0;
}

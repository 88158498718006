.performance-card {
	min-width: 200px;
	max-width: 350px;
	border-radius: 10px;
	overflow: hidden;

	display: flex;
	flex-direction: column;

	position: relative;

	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

	font-family: "Space Grotesk";
}

.performance-card > img {
	width: 100%;
	border-radius: 0px;
	margin: 0px;

	box-shadow: none;
}

.performance-link > img {
	width: 100%;
	border-radius: 0px;
	margin: 0px;

	box-shadow: none;
}

.performance-card--country {
	display: flex;
	align-items: center;
	gap: 8px;
	font-weight: bold;
	justify-content: center;

	/* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
	/* border: 1px solid grey; */

	position: absolute;
	background-color: white;
	border-radius: 15px;
	padding-inline: 20px;
	padding-block: 5px;
	top: -1rem;
	left: 5%;
}

.performance-card--country > span {
	font-size: 0.875rem;
}

.performance-card--position {
	color: grey;
}

.performance-card--info {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	padding: 25px;
}

.performance-card--text {
	display: flex;
	flex-direction: column;
	gap: 6px;
	margin: 0px;
}

.performance-card--input {
	-moz-appearance: textfield;

	width: 70px;
	min-width: 60px;
	height: 70px;
	min-height: 60px;
	text-align: center;

	border: 2px solid grey;
	border-radius: 15px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.performance-card--artist {
	font-weight: bold;
	font-size: 1.25rem;
}

.performance-card--song {
	font-size: 1rem;
	display: flex;
	align-items: center;
	gap: 10px;
}

.performance-card--song > a {
	display: flex;
	align-items: center;
	justify-content: center;

	text-decoration: inherit;
	color: inherit;
}

.yt-logo{
	position: absolute;
	top: 150px;
	right: 15px;
	z-index: 99;
}

.yt-logo > i {
	
	color: red;
	font-size: 1.5rem;
}

nav {
    background-color: #030B27;
    color: white;
    height: 85px;
    width: 100%;
    display: flex;
    align-items: center;

    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    padding-inline: 10px;

}

.desktop-nav{
    gap: 20px;
}

main{
    padding-top: 85px;
}

.nav--logo{
    height: 60px;
}

.nav--items{
    list-style: none;

    display: flex;
    flex: 1 1 1;
    gap: 10px;
    margin: 0;
    padding: 0;

    overflow-x: auto;
}

.nav--item {
    border: 2px solid white;
    padding: 5px;
    border-radius: 15px;

    font-family: 'Space Grotesk';
    cursor: pointer;
    height: 35px;
    white-space: nowrap;
}

.nav--items:last-child {
    margin-left: auto;
}

.nav--selected {
    background-color: #EAE9EB;
    color: #030B27;
    font-weight: bold;
}

.nav--items > li > a {
    text-decoration: inherit;
    color: inherit;
}

.nav--logout{
    margin-left: auto;
    background-color: transparent;
    color: white;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

